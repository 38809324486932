#cookiescript_show_all_partners_button {
  font-size: 12px !important;
}

#cookiescript_accept,
#cookiescript_reject,
#cookiescript_save {
  font-size: 14px !important;
}

.leaflet-popup-content {
  min-width: 200px;
}

.slick-slider:not(.slick-initialized) .slick-track {
  display: flex;
}

.slick-slider:not(.slick-initialized) .slick-prev {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.slick-slider:not(.slick-initialized) .slick-next {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}
